import { useEffect, useMemo, useState } from 'react';
import { InputAdornment, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  ContrastButtonMain,
  ContrastButtonSecondary,
} from '../../../ContrastButton/ContrastButton.component';
import { ReelQuantity } from '../../../ReelQuantity/ReelQuantity.component';
import { TableRowDialog } from '../../../TableRowDialog/TableRowDialog.component';
import { Show } from '../../../Show/Show.component';

import { convertQuantity } from '../../../../utils/units';
import PartialCut from '../../../../types/PartialCut';
import { CompanyType, MeasurementUnits } from '../../../../enums';
import {
  useCompany,
  useCurrentUser,
  usePortalSettings,
} from '../../../../hooks';
import { StandardType } from '../../../../enums/StandardType';
import * as Sx from './RowCollapse.styles';
import { RowCollapseProps } from './RowCollapse.props';

export const RowCollapse = ({
  item: reel,
  openedCollapse,
  partialCutError,
  reserve,
  reserving,
  reservingCustomer,
  reservingUser,
  setOpenedCollapse,
  setPartialCutError,
  units,
}: RowCollapseProps) => {
  const { t } = useTranslation();
  const [note, setNote] = useState<string>('');
  const [packagesAmount, setPackagesAmount] = useState<number>(1);
  const [partialCutLength, setPartialCutLength] = useState<number>(0);
  const companyId =
    reservingCustomer?.company.companyId ?? reservingUser.companyRef.id;
  const { item: company } = useCompany(companyId);
  const currentUser = useCurrentUser();
  const { item: currentUserCompany } = useCompany(currentUser.companyRef.id);

  const { item: portalSettings } = usePortalSettings();

  useEffect(() => {
    if (openedCollapse !== reel.id) {
      setNote('');
    }
  }, [openedCollapse, reel.id]);

  const getPartialCut = (value: number): PartialCut | undefined => {
    if (
      value ===
        Number(convertQuantity(units, reel.quantity, reel.productType)) ||
      !value
    ) {
      return undefined;
    }
    return { length: value, units: units };
  };

  const isPartialCutPossible = useMemo(() => {
    const minCutLengthCsa = portalSettings?.minimumCutLengthRemainingCsa ?? 0;
    const minCutLengthUl = portalSettings?.minimumCutLengthRemainingUl ?? 0;
    if (reel.standardType === StandardType.CSA) {
      return reel.quantity > minCutLengthCsa;
    } else if (reel.standardType === StandardType.UL) {
      return (
        Number(convertQuantity(MeasurementUnits.Imperial, reel.quantity)) >
        minCutLengthUl
      );
    } else {
      return (
        Number(convertQuantity(units, reel.quantity)) >
        (units === MeasurementUnits.Metric ? minCutLengthCsa : minCutLengthUl)
      );
    }
  }, [portalSettings, reel, units]);

  return (
    <TableRowDialog
      buttons={
        <>
          <ContrastButtonMain
            disabled={reserving}
            variant="contained"
            onClick={() =>
              reserve(
                reel,
                note,
                reel.packageLength &&
                  Number.isInteger(reel.quantity / reel.packageLength)
                  ? packagesAmount
                  : 0,
                getPartialCut(partialCutLength)
              )
            }
          >
            {t('common.boolean.true')}
          </ContrastButtonMain>

          <ContrastButtonSecondary
            disabled={reserving}
            onClick={() => setOpenedCollapse(null)}
            variant="outlined"
          >
            {t('common.boolean.false')}
          </ContrastButtonSecondary>
        </>
      }
      title={t('inventory.reserveQuestion')}
    >
      <Typography variant="h6" sx={Sx.mobileDialogContent}>
        {reel.description} - {reel.cableRef.id}
      </Typography>

      <Typography variant="body1" sx={Sx.mobileDialogContent}>
        <ReelQuantity item={reel} unitsType={units} />
      </Typography>

      <Show
        if={
          !!reel.packageLength &&
          Number.isInteger(reel.quantity / reel.packageLength)
        }
      >
        <TextField
          required
          label={t('inventory.reserveAmountLabel')}
          variant="filled"
          type="number"
          value={packagesAmount}
          onChange={(e) =>
            setPackagesAmount((_) => {
              const val = Number(e.target.value);
              if (val <= 0) {
                return 1;
              } else if (
                val >
                Number(reel.packageLength && reel.quantity / reel.packageLength)
              ) {
                return Number(
                  reel.packageLength && reel.quantity / reel.packageLength
                );
              } else {
                return Math.floor(val);
              }
            })
          }
          sx={Sx.quantitySelectionField}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            min: 1,
            max:
              reel.packageLength && Number(reel.quantity / reel.packageLength),
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment sx={{ pt: 2 }} position="end">
                {'x'}
                {convertQuantity(units, reel.packageLength, reel.productType)}
                {t(`units.${units}.length`)}
              </InputAdornment>
            ),
          }}
        />
      </Show>

      <Show
        if={
          !reel.numberOfPieces &&
          !reel.packageLength &&
          !!company?.allowPartialCuts &&
          (currentUserCompany?.type !== CompanyType.Agency ||
            !!currentUserCompany.allowPartialCuts) &&
          isPartialCutPossible
        }
      >
        <TextField
          label={t('inventory.partialCutLabel')}
          variant="filled"
          type="text"
          value={partialCutLength ? partialCutLength : ''}
          error={partialCutError}
          onChange={(e) => {
            setPartialCutError(false);
            return setPartialCutLength((_) => {
              const val = Number(e.target.value);
              const convertedQuantity = Number(
                convertQuantity(units, reel.quantity, reel.productType)
              );
              if (val < 0) {
                return 0;
              } else if (val > convertedQuantity) {
                return convertedQuantity;
              } else {
                return val;
              }
            });
          }}
          sx={Sx.quantitySelectionField}
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment sx={{ pt: 2 }} position="end">
                {t(`units.${units}.length`)}
              </InputAdornment>
            ),
          }}
        />
      </Show>

      <TextField
        inputProps={{ maxLength: 255 }}
        label={t('reservations.fields.reservationNote')}
        multiline
        onChange={(e) => setNote(e.target.value)}
        sx={Sx.reservationCommentField}
        value={note}
        variant="filled"
      />
    </TableRowDialog>
  );
};
