import { MeasurementUnits, ProductType } from '../enums';
import { Nullable } from '../types';

export const METER_TO_FEET_RATIO = 3.28084;
const KILOGRAMS_TO_POUNDS_RATIO = 2.20462;

const UL_SPECIFIC_FEET_TO_METER_RATIO = 0.304;
const SPECIAL_CONVERSION_PRODUCT_TYPES = [
  ProductType.BX,
  ProductType.FA,
  ProductType.HCF,
  ProductType.HCF_ABG,
  ProductType.MC,
  ProductType.MC_ABG,
  ProductType.XHHW2,
  ProductType.THHN,
];

export const convertQuantity = (
  type: MeasurementUnits,
  value = 0,
  productType: Nullable<ProductType> = null
) => {
  if (type === MeasurementUnits.Imperial) {
    if (
      productType &&
      SPECIAL_CONVERSION_PRODUCT_TYPES.indexOf(productType) >= 0
    ) {
      return Math.floor(value / UL_SPECIFIC_FEET_TO_METER_RATIO);
    } else {
      return Math.floor(value * METER_TO_FEET_RATIO);
    }
  }

  return String(value);
};

export const convertWeight = (type: MeasurementUnits, value = 0) => {
  if (value <= 0) {
    return '';
  }

  if (type === MeasurementUnits.Imperial) {
    return (value * KILOGRAMS_TO_POUNDS_RATIO).toFixed(2);
  }

  return String(value);
};
