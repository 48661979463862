import { TFunction } from 'react-i18next';
import { DateTime } from 'luxon';
import formatReservationNumber from '../../utils/formatReservationNumber';
import { convertQuantity, convertWeight } from '../../utils/units';
import { Reservation, SanitizedReservation } from '../../models';
import { MeasurementUnits, TimeZone } from '../../enums';
import report from './report';

const PREFIX = 'NC_Reservations';
const NAMESPACE = 'reports.reservations';
const EXPORTED_KEYS = [
  'reservationNumberFormatted',
  'madeByName',
  'madeForName',
  'status',
  'reservedTimeStr',
  'reelId',
  'reelDescription',
  'note',
  'reelQuantity',
  'reelWeight',
];

class reservationReportCsv extends report<Reservation> {
  private readonly data: Reservation[];
  private readonly measurementUnits: MeasurementUnits;
  private readonly isHistorical: boolean;

  constructor(
    data: Reservation[],
    measurementUnits: MeasurementUnits,
    t: TFunction<'translation', undefined>,
    isHistorical = false
  ) {
    super(
      PREFIX,
      NAMESPACE,
      !isHistorical ? [...EXPORTED_KEYS, 'expirationTimeStr'] : EXPORTED_KEYS,
      t
    );

    this.data = data;
    this.measurementUnits = measurementUnits;
    this.isHistorical = isHistorical;
  }

  protected getFilename(): string {
    return `${PREFIX}_${DateTime.now().toFormat('LLddyyyyHHmmss')}.csv`;
  }

  protected getHeaders(): string[] {
    const headers = [
      `"${this.t(`${this.namespace}.reservationNumber`)}"`,
      `"${this.t(`${this.namespace}.reservedBy`)}"`,
      `"${this.t(`${this.namespace}.reservedFor`)}"`,
      `"${this.t(`${this.namespace}.status`)}"`,
      `"${this.t(`${this.namespace}.date`)}"`,
      `"${this.t(`${this.namespace}.reelId`)}"`,
      `"${this.t(`${this.namespace}.product`)}"`,
      `"${this.t(`${this.namespace}.note`)}"`,
      `"${this.t(`${this.namespace}.reelQuantity`)},${this.t(
        `units.${this.measurementUnits}.length`
      )}"`,
      `"${this.t(`${this.namespace}.reelWeight`)},${this.t(
        `units.${this.measurementUnits}.weight`
      )}"`,
    ];
    return !this.isHistorical
      ? [...headers, `"${this.t(`${this.namespace}.expirationTimeStr`)}"`]
      : headers;
  }

  protected getSanitizedData(): SanitizedReservation[] {
    return this.data.map((value) => {
      return {
        ...value,
        reservationNumberFormatted: formatReservationNumber(
          value.reservationNumber
        ),
        status: this.t(`reservations.shortStatuses.${value.status}`),
        reelDescription: value.reel.description,
        reelQuantity: Number(
          convertQuantity(
            this.measurementUnits,
            value.reel.quantity,
            value.reel.productType
          )
        ),
        reelWeight: Number(
          convertWeight(this.measurementUnits, value.reel.weight)
        ),
        expirationTimeStr: DateTime.fromMillis(value.expirationTime.toMillis())
          .setLocale('en')
          .setZone(TimeZone.EasternTime)
          .toLocaleString(DateTime.DATETIME_MED),
        reservedTimeStr: DateTime.fromMillis(value.reservedTime.toMillis())
          .setLocale('en')
          .setZone(TimeZone.EasternTime)
          .toLocaleString(DateTime.DATETIME_MED),
      };
    });
  }

  static generate(
    data: Reservation[],
    measurementUnits: MeasurementUnits,
    t: TFunction<'translation', undefined>,
    isHistorical = false
  ) {
    const report = new reservationReportCsv(
      data,
      measurementUnits,
      t,
      isHistorical
    );
    report.generateCSV();
  }
}

export default reservationReportCsv;
