import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { DateTime, Interval } from 'luxon';

import { TimestampCountdownProps } from './TimestampCountdown.props';

export const TimestampCountdown = ({
  maxDelay,
  onExpire,
  timestamp,
  timeZone,
  children,
  dayOffs,
  weekDaysOff,
  status,
}: TimestampCountdownProps) => {
  const [currentDate, setCurrentDate] = useState<DateTime>(DateTime.now());
  const [onExpireCalled, setOnExpireCalled] = useState<boolean>(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(DateTime.now());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (timestamp) {
      const dateTime = DateTime.fromMillis(timestamp.toMillis());

      if (!onExpireCalled && onExpire && dateTime < currentDate) {
        onExpire();
        setOnExpireCalled(true);
      }
    }
  }, [currentDate, onExpire, onExpireCalled, timestamp]);

  const countdown = useMemo(() => {
    let endDateTime = timestamp
      ? DateTime.fromMillis(timestamp.toMillis())
      : null;
    if (!endDateTime || !timeZone) {
      return null;
    }
    let startDateTime = currentDate;
    let daysOffDates = dayOffs
      ?.filter((d) => {
        const dayDate = DateTime.fromMillis(d.toMillis());
        return (
          endDateTime &&
          dayDate.startOf('day') <= endDateTime.startOf('day') &&
          dayDate.startOf('day') >= startDateTime.startOf('day')
        );
      })
      .map((d) => DateTime.fromMillis(d.toMillis()));

    // Split the interval by days
    const interval = Interval.fromDateTimes(startDateTime, endDateTime);
    const subIntervals = interval.splitBy({ days: 1 });
    // Find the weekdays off in the interval
    subIntervals.forEach((s) => {
      if (
        s.start &&
        weekDaysOff?.includes(s.start.weekday === 7 ? 0 : s.start.weekday)
      ) {
        daysOffDates?.push(s.start);
      }
    });

    const isDayOff = (date: DateTime) =>
      daysOffDates && daysOffDates.some((dod) => date.hasSame(dod, 'day'));

    // Are any days off in the interval between now and expiration date?
    if (
      daysOffDates &&
      daysOffDates?.length > 0 &&
      status === 'reserved' &&
      !endDateTime.hasSame(startDateTime, 'day')
    ) {
      // Is current date is a day off?
      if (isDayOff(startDateTime)) {
        // Find the next business day
        do {
          // Remove the day off from the list
          const currentStartDateTime = startDateTime;
          daysOffDates = daysOffDates.filter(
            (dod) => !currentStartDateTime.hasSame(dod, 'day')
          );
          // Move to the next day
          startDateTime = startDateTime.plus({ days: 1 });
        } while (isDayOff(startDateTime));
      }

      // Remove off days
      endDateTime = endDateTime.minus({ days: daysOffDates.length });
    }
    return endDateTime.diff(startDateTime, ['hours', 'minutes']);
  }, [timestamp, timeZone, currentDate, dayOffs, status, weekDaysOff]);

  if (
    !timestamp ||
    !timeZone ||
    DateTime.fromMillis(timestamp.toMillis()) < currentDate
  ) {
    return null;
  }

  return (
    <>
      <Box>{countdown?.toFormat('hh:mm')}</Box>
      {countdown && countdown?.hours < maxDelay ? children : null}
    </>
  );
};
